import { combineReducers } from "redux";
import { queryReducer } from "./query";
import { relatedWorklistReducer } from "./related-worklist";
import { reportReducer } from "./report";
import { worklistReducer } from "./worklist";
import { techlistReducer } from "./techlist";
import { techlistReportReducer } from "./techlist-report";
import { relatedTechlistReducer } from "./related-techlist";
import { commonReducer } from "./common";
import { filmboxReducer } from "./filmbox";
import { logoutReducer } from "./logout";

const reducers = combineReducers({
   report: reportReducer,
   worklist: worklistReducer,
   relatedWorklist: relatedWorklistReducer,
   query: queryReducer,
   techlist: techlistReducer,
   techlistReport: techlistReportReducer,
   relatedTechlist: relatedTechlistReducer,
   common: commonReducer,
   filmbox: filmboxReducer,
   logout: logoutReducer,
});

export type RootState = ReturnType<typeof reducers>;

// redux-state-sync initializing action
export default (state: any, action: any) => {
   if (action.type === "&_RECEIVE_INIT_STATE") {
      const initialState = action.payload;
      initialState.report.copyAndPasteReport = undefined;
      return initialState;
   }
   return reducers(state, action);
};
