export interface RowDetail {
   id: string,
   readingStatus: string,
   processingStatus: string,
   studyStatus: string,
   teleStatus: string,
   edit?: boolean,
   studyDtime?: string,
   studyDescription?: string,
   referralFacilityId?: string,
   requestHospital?: string,
   patientID?: string,
   patientName?: string,
   patientBirthDate?: string,
   patientSex?: string,
   opinionUpdateUser?: string,
   opinionUpdateUserId?: string,
   isSaveVerified?: boolean,
   transcribeReadRoles?: string,
   isContainsMembers?: boolean,
   userId?: string,
   isContainsFacilityMembers? : boolean,
}

export interface WorklistRow {
   detail: RowDetail,
   rows: RowDetail[],
}
export interface Filter {
   detail: {
      filterModel: {};
      sortModel: {};
   }
}
export interface AppliedFilter {
   filterModel?: object;
   appliedDateFilters?: object;
}
interface RedrawRows {
   ids: [],
   type: string,
   selection: boolean
}
export enum RedrawRowsType {
   REPORTED
}

interface WorklistState {
   row?: WorklistRow;
   filter?: Filter; // 적용할 필터
   appliedFilter?: AppliedFilter; // 적용된 필터
   appliedSortModel?: object;
   redrawRows?: RedrawRows;
}
const INITIAL_WORKLIST_STATE: WorklistState = {
   row: undefined,
   filter: undefined,
   appliedFilter: undefined,
   appliedSortModel: undefined,
   redrawRows: undefined,
};
export enum WorklistActionType {
   SELECT_ROW = "selectWorklistRow",
   CLEAR_ROW_SELECTION = "clearSelection",
   SET_FILTER = "setFilter",
   SET_APPLIED_FILTER = "setAppliedFilter",
   SET_APPLIED_SORT_MODEL = "setAppliedSortModel",
   REDRAW_ROWS = "redrawWorklistRows",
}
export interface SelectRowAction {
   type: WorklistActionType.SELECT_ROW;
   payload: WorklistRow;
}
export interface ClearRowAction {
   type: WorklistActionType.CLEAR_ROW_SELECTION;
}
export interface SetFilter {
   type: WorklistActionType.SET_FILTER;
   payload: Filter;
}
export interface SetAppliedFilter {
   type: WorklistActionType.SET_APPLIED_FILTER;
   payload: AppliedFilter;
}
export interface SetAppliedSortModel {
   type: WorklistActionType.SET_APPLIED_SORT_MODEL;
   payload: object;
}
export interface RedrawRowsAction {
   type: WorklistActionType.REDRAW_ROWS;
   payload: RedrawRows;
}
export type WorklistAction =
| SelectRowAction
| ClearRowAction
| SetFilter
| SetAppliedFilter
| SetAppliedSortModel
| RedrawRowsAction;

export function worklistReducer(state = INITIAL_WORKLIST_STATE, action: WorklistAction): WorklistState {
   switch (action.type) {
   case WorklistActionType.SELECT_ROW: {
      return { ...state, row: action.payload };
   }
   case WorklistActionType.CLEAR_ROW_SELECTION: {
      return { ...state, row: undefined };
   }
   case WorklistActionType.SET_FILTER: {
      return { ...state, filter: action.payload };
   }
   case WorklistActionType.SET_APPLIED_FILTER: {
      return { ...state, appliedFilter: action.payload };
   }
   case WorklistActionType.SET_APPLIED_SORT_MODEL: {
      return { ...state, appliedSortModel: action.payload };
   }
   case WorklistActionType.REDRAW_ROWS: {
      return { ...state, redrawRows: action.payload };
   }
   default:
      return state;
   }
}
