interface UserConfig {
   id: string | undefined
   userId: string | undefined
   layout: any
   tabIndex: any
   grid: any
   filmbox: any
   certAtLogin: boolean
}
interface DialogState {
   type: DialogType,
   actionType: DialogActionType,
   message: any,
   open: boolean
}
export interface PopupReport {
   open: boolean,
   editOpinion?: object
}
interface CommonState {
   category: number;
   userConfig: UserConfig;
   dialog?: DialogState;
   customContextMenu?: string;
   prefetch: boolean;
   popupReport?: PopupReport;
}
const INITIAL_COMMON_STATE: CommonState = {
   category: 0,
   userConfig: {
      id: undefined,
      userId: undefined,
      layout: {
         isReportScroll: false,
         isIndividualScroll: false,
         isPopupReadingTemplate: false,
         isPopupRelatedReport: false,
         isPopupPin: true,
      },
      tabIndex: {},
      grid: {},
      filmbox: {},
      certAtLogin: false,
   },
   dialog: undefined,
   customContextMenu: undefined,
   prefetch: true,
   popupReport: {
      open: false,
      editOpinion: {},
   },
};
export enum CommonActionType {
   SET_CATEGORY = "setCategory",
   SET_USER_CONFIG = "setUserConfig",
   OPEN_DIALOG = "openDialog",
   CLOSE_DIALOG = "closeDialog",
   SHOW_CONTEXT_MENU = "showContextMenu",
   HIDE_CONTEXT_MENU = "hideContextMenu",
   TOGGLE_PREFETCH = "togglePrefetch",
   OPEN_POPUP_REPORT = "openPopupReport",
   CLOSE_POPUP_REPORT = "closePopupReport"
}
export enum DialogType {
   REPORT_CONFIRM_DIALOG,
   CONFIRM_DIALOG,
   VERIFY_DIALOG,
   MODIFY_EXAM_DIALOG,
   NEW_EXAM_DIALOG,
   MERGE_EXAM_DIALOG,
   SPLIT_EXAM_DIALOG,
   DICOM_SEND_DIALOG,
   BONEAGE_REPORT_DIALOG,
   SHARE_TO_PATIENT_DIALOG,
   PASSWORD_DIALOG,
   USERINFO_DIALOG,
   SUPPORT_DIALOG,
   SETTING_DIALOG,
   CERTIFICATION_DIALOG,
   TELE_REQUEST_DAILOG,
   REFERRAL_DAILOG
}
export enum DialogActionType {
   DELETE_READING_TEMPLATE,
   VERIFY,
   UN_VERIFY,
   MATCH,
   UN_MATCH,
   DELETE_STUDY,
   MODIFY_EXAM,
   NEW_EXAM,
   MERGE_EXAM,
   CANCEL_MERGE,
   SPLIT_EXAM,
   DICOM_SEND,
   REPORT_NOT_SAVED,
   REPORT_ALREADY_REQUEST,
   REPORT_NOT_VERIFY,
   REPORT_SAVE_OPINION,
   REPORT_TRANSCRIBE_OPINION,
   MISMATCH,
   SIGN_CANCEL,
   PREFETCH_CLEAR,
   CHANGE_CLINICAL_INFO,
   LOGOUT,
   BONEAGE_REPORT
}
export enum CustomContextMenuType {
   SYSTEM = "SYSTEM",
   REPORT = "REPORT",
   TECH_REPORT = "TECH_REPORT",
   CLINICAL_INFO = "CLINICAL_INFO",
   QUICK_SEARCH = "QUICK_SEARCH",
   REPORT_RELATED = "REPORT_RELATED"
}
interface SetCategoryAction {
   type: CommonActionType.SET_CATEGORY;
   payload: number;
}
interface SetUserConfigAction {
   type: CommonActionType.SET_USER_CONFIG;
   payload: UserConfig;
}
interface OpenDialogAction {
   type: CommonActionType.OPEN_DIALOG;
   payload: DialogState;
}
interface CloseDialogAction {
   type: CommonActionType.CLOSE_DIALOG;
   // payload: DialogActionType;
}
interface ShowContextMenuAction {
   type: CommonActionType.SHOW_CONTEXT_MENU;
   payload: string;
}
interface HideContextMenuAction {
   type: CommonActionType.HIDE_CONTEXT_MENU;
}
interface TogglePrefetchAction {
   type: CommonActionType.TOGGLE_PREFETCH;
   payload: boolean;
}
interface OpenPopupReportAction {
   type: CommonActionType.OPEN_POPUP_REPORT;
   payload: PopupReport;
}
interface ClosePopupReportAction {
   type: CommonActionType.CLOSE_POPUP_REPORT;
   payload: PopupReport;
}

type CommonAction =
   | SetCategoryAction
   | SetUserConfigAction
   | OpenDialogAction
   | CloseDialogAction
   | ShowContextMenuAction
   | HideContextMenuAction
   | TogglePrefetchAction
   | OpenPopupReportAction
   | ClosePopupReportAction;

export function commonReducer(state = INITIAL_COMMON_STATE, action: CommonAction): CommonState {
   switch (action.type) {
   case CommonActionType.SET_CATEGORY: {
      return { ...state, category: action.payload };
   }
   case CommonActionType.SET_USER_CONFIG: {
      return { ...state, userConfig: action.payload };
   }
   case CommonActionType.OPEN_DIALOG: {
      return { ...state, dialog: action.payload };
   }
   case CommonActionType.CLOSE_DIALOG: {
      return { ...state, dialog: undefined };
   }
   case CommonActionType.SHOW_CONTEXT_MENU: {
      return { ...state, customContextMenu: action.payload };
   }
   case CommonActionType.HIDE_CONTEXT_MENU: {
      return { ...state, customContextMenu: undefined };
   }
   case CommonActionType.TOGGLE_PREFETCH: {
      return { ...state, prefetch: action.payload };
   }
   case CommonActionType.OPEN_POPUP_REPORT: {
      return { ...state, popupReport: action.payload };
   }
   case CommonActionType.CLOSE_POPUP_REPORT: {
      return { ...state, popupReport: action.payload };
   }
   default: {
      return state;
   }
   }
}
