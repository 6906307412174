/* eslint-disable no-param-reassign */
import moment from "moment-timezone";

class CommonuUtils {
   constructor() {
      if (CommonuUtils._instance) return CommonuUtils._instance;
      CommonuUtils._instance = this;
   }

   /**
    * function argToJson
    *
    * @param {string} popup.location.hash
    * @return {object} {studyId: "", old: ""}
    * */
   argToJson(s) {
      if (!s || s.length === 0) return {};

      const str = `studyId=${s}`;
      return str.split("&").reduce((prev, curr) => {
         const p = curr.split("=");
         prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
         return prev;
      }, {});
   }

   /**
    * object empty check
    *
    * @param object
    * @returns {boolean}
    */
   isEmptyObject(param) {
      return Object.keys(param).length === 0 && param.constructor === Object;
   }

   /**
    * value empty check
    * @param param
    * @return {boolean}
    */
   isEmptyValue(param) {
      return param === "" || param === null || param === undefined;
   }

   isEmptyArr(arr)  {
      return Array.isArray(arr) && arr.length === 0 || arr === undefined;
   }

   /**
    * value number check
    * @param param
    * @return {boolean}
    */
   isNumeric(param) {
      return /^-{0,1}\d+$/.test(param);
   }

   /**
    * Check Array Equals
    * @param a
    * @param b
    * @returns {boolean|*}
    */
   arrayEquals(a, b) {
      a.sort(); b.sort();
      return Array.isArray(a) &&
         Array.isArray(b) &&
         a.length === b.length &&
         a.every((val, index) => val === b[index]);
   }

   compareArray(a, b) {
      return JSON.stringify(a) === JSON.stringify(b);
   }

   /**
    * convert timestemp to date
    * @param {number} timestemp
    * @return {string} date
    */
   convertTimestampToDate(timestemp) {
      if (typeof timestemp !== "number") {
         // eslint-disable-next-line no-param-reassign
         timestemp = parseInt(timestemp, 10);
      }
      const d = new Date(timestemp);
      const s =
         `${this.leadingZeros(d.getFullYear(), 4)  }-${
            this.leadingZeros(d.getMonth() + 1, 2)  }-${
            this.leadingZeros(d.getDate(), 2)  } ${
            this.leadingZeros(d.getHours(), 2)  }:${
            this.leadingZeros(d.getMinutes(), 2)  }:${
            this.leadingZeros(d.getSeconds(), 2)}`;
      return s;
   }

   leadingZeros(n, digits) {
      let zero = "";
      // eslint-disable-next-line no-param-reassign
      n = n.toString();

      if (n.length < digits) {
         for (let i = 0; i < digits - n.length; i++)
            zero += "0";
      }
      return zero + n;
   }

   /**
    * 입력된 mlisecond 값을 Date 형식으로 변환
    * */
   getMilisecToDate(milisec){
      const year = milisec.getFullYear();
      let month = milisec.getMonth()+1;
      if(month < 10) month = `0${month}`;
      let date = milisec.getDate();
      if(date < 10) date = `0${date}`;
      let hour = milisec.getHours();
      if(hour < 10) hour = `0${hour}`;
      let min = milisec.getMinutes();
      if(min < 10) min = `0${min}`;
      let sec = milisec.getSeconds();
      if(sec < 10) sec = `0${sec}`;
      return `${year}-${month}-${date} ${hour}:${min}:${sec}`;
   }

   elapsedTimeCheck(date){
      const approveTime = new Date(parseInt(date, 10));
      const nowTime = new Date();
      return moment(nowTime)
         .diff(approveTime, "minutes");
   }

   uuidv4() {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
         // eslint-disable-next-line no-bitwise
         (c^crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
   }

   joinClassName(...list) {
      return list.join(" ");
   }

   /*
   * 깊은 복사
   * https://chaewonkong.github.io/posts/js-deep-copy.html
   * Array 가 Object 로 변환 되어 오류 발생함.
   * 속도측면에: 재귀 > JSON.parse
   * */
   cloneObject(obj) {
      // let clone = {};
      // for (let key in obj) {
      //    if (typeof obj[key] == "object" && obj[key] != null) {
      //       clone[key] = this.cloneObject(obj[key]);
      //    } else {
      //       clone[key] = obj[key];
      //    }
      // }
      //
      return JSON.parse(JSON.stringify(obj))
      // return clone;
   }

   padLeft(str, nLength, sPadString){
      nLength >>= 0;
      sPadString = String(sPadString || " ");
      if (str.length > nLength) return str;

      nLength -= str.length;

      if (nLength > sPadString.length) {
         sPadString += sPadString.repeat(nLength / sPadString.length);
      }

      return sPadString.slice(0, nLength) + str;
   }

   getQueryParams = (query = window.location.search) => {
      const p = {};

      if(!query || query.trim() === "") return p;

      const params = new URLSearchParams(query);
      // eslint-disable-next-line no-restricted-syntax
      for(const [k, v] of params) {
         const value = v && decodeURIComponent(v);
         if(Object.keys(p).includes(k)) p[k].push(value);
         else p[k] = [value];
      }

      return p;
   }
}

const commonuUtils = new CommonuUtils();
Object.freeze(commonuUtils);

export default commonuUtils;

